import Vue from 'vue';

import { ScrollTop, RichText, Section, Button, ButtonNuxt, PrismicImage } from '@swegaming-ab/vue-components';

import ButtonTarget from '@/components/buttons/ButtonTarget';
import Bar from '@/components/Bar.vue';
import RatingStars from '@/components/RatingStars.vue';

const components = {
    Section,
    Button,
    ButtonNuxt,
    PrismicImage,
    RichText,
    ButtonTarget,
    Bar,
    ScrollTop,
    RatingStars
};

Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component);
});
